<template>
    <div class="content_wrapper">
        <base-title :text="block_data.title" :text-align-center="ui.is_mobile_resolution" :title_level="'1'"></base-title>

        <div v-if="ui.is_loading_images">
            <loading-indicator type="spinner"></loading-indicator>
        </div>
        <div v-if="ui.no_images" class="error_wrapper">
            <p>Error</p>
        </div>
        <div v-if="!ui.is_loading_images && !ui.no_images" :style="column_count_style">
            <div v-for="(image, image_index) in current_images" :key="'img_idx_' + image_index" class="image_wrapper">

                <div class="image_container">
                    <base-image :imgSrc="setImageSrc(image)"></base-image>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from './common/BaseTitle.vue';
import BaseImage from './common/BaseImage.vue';
import LoadingIndicator from './common/LoadingIndicator.vue';

import AngleRightSvg from './svg-components/AngleRightSvg.vue';
import AngleLeftSvg from './svg-components/AngleLeftSvg.vue';

export default {
    name: 'images-grid',

    props: {
        storage_path: String,
        block_data: Object,
    },

    components: {
        BaseTitle,
        BaseImage,
        LoadingIndicator,
        AngleRightSvg,
        AngleLeftSvg
    },

    data() {
        return {
            ui: {
                is_loading_images: false,
                no_images: false,
                is_mobile_resolution: false,
                is_desktop_resolution: false,
            },
            columns_desktop: null,
            columns_mobile: null,
            images: [],
        }
    },

    computed: {
        current_images() {
            return this.block_data.images;
        },

        width_style() {
            if (this.current_images.length > 0) {
                return {
                    'width': `${100 / (this.ui.is_mobile_resolution ? this.block_data.nb_of_columns_mobile : this.block_data.nb_of_columns_desktop)}%`
                };
            } else {
                return 'width: unset';
            }
        },
        column_count_style() {
            return {
                "column-count": `${
                    this.ui.is_mobile_resolution
                        ? this.columns_mobile
                        : this.columns_desktop
                }`,
            };
        }
    },

    watch: {},

    created() {

        let self = this;

        // console.log('block_data', self.block_data);

        window.addEventListener('resize', self._onResize);
        self._onResize();

        self.setColumns();

    },

    mounted() { },

    beforeDestroy() {
        let self = this;
        window.removeEventListener('resize', self._onResize);
    },

    methods: {
        setColumns() {
            let self = this;

            let columns_mobile_from_block_data = typeof self.block_data.nb_of_columns_mobile === 'string' ? parseInt(self.block_data.nb_of_columns_mobile) : self.block_data.nb_of_columns_mobile,
                columns_desktop_from_block_data = typeof self.block_data.nb_of_columns_desktop === 'string' ? parseInt(self.block_data.nb_of_columns_desktop) : self.block_data.nb_of_columns_desktop;

            self.columns_desktop = columns_desktop_from_block_data;
            self.columns_mobile = columns_mobile_from_block_data;
        },
        setImageSrc(image_url) {

            if (image_url) {
                if (image_url.indexOf('http') !== -1) {
                    return image_url;
                } else {
                    return this.storage_path + '/' + image_url;
                }
            } else {
                return '';
            }
        },

        // resize
        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {

            if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },

    },
}
</script>

<style lang="scss" scoped>
.content_wrapper {

    .error_wrapper {
        display: flex;
        justify-content: center;
    }

    .images_wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // margin: -10px;
        gap: 0;

        @media (min-width: 1024px) {
            margin: -15px;
            gap: unset;
        }

        .image_wrapper {
            padding: 10px;
            flex: 0 0 50%;
            flex-grow: 1;

            @media (min-width: 1024px) {
                padding: 15px;
            }

            .image_container {
                width: 100%;
                max-width: 350px;
                height: 100%;
                margin: 0 auto;
            }
        }

    }
}
</style>