<template>
    <div class="content_wrapper">
        <base-title
            v-if="block_data.title"
            :text="block_data.title"
            :title_level="block_data.title_level"
        ></base-title>

        <div
            v-if="block_data.description"
            class="text_wrapper"
            :style="column_count_style"
            v-html="block_data.description"
        ></div>

        <template v-if="block_data.link_type == 'internal'">
            <div class="more_posts">
                <a
                    v-if="block_data.internal_link !== null"
                    :href="internal_link_slug"
                    target="_blank"
                >
                    <span>
                        {{ block_data.text_button }}
                    </span>
                    <ChevronsRight />
                </a>
            </div>
        </template>

        <template v-if="block_data.link_type == 'external'">
            <div class="more_posts">
                <a
                    v-if="block_data.external_link"
                    :href="block_data.external_link"
                    target="_blank"
                >
                    <span>
                        {{ block_data.text_button }}
                    </span>
                    <ChevronsRight />
                </a>
            </div>
        </template>
    </div>
</template>

<script>
import BaseTitle from "./common/BaseTitle.vue";
import axios from "axios";
import ChevronsRight from "./svg-components/ChevronsRight.vue";

export default {
    name: "section-title-with-description",

    props: ["block_data"],

    components: {
        BaseTitle,
        ChevronsRight,
    },

    data() {
        return {
            ui: {
                is_mobile_resolution: false,
                is_desktop_resolution: false,
            },

            columns_desktop: null,
            columns_mobile: null,
            internal_link_slug: null,
        };
    },

    computed: {
        column_count_style() {
            console.log(this.block_data);
            if (this.columns_desktop == null || this.columns_mobile == null) {
                return {
                    "column-count": "1",
                };
            } else {
                return {
                    "column-count": `${
                        this.ui.is_mobile_resolution
                            ? this.columns_mobile
                            : this.columns_desktop
                    }`,
                };
            }
        },
    },

    watch: {},

    created() {
        let self = this;

        if (self.block_data?.nb_of_columns_mobile) {
            self.columns_mobile =
                typeof self.block_data.nb_of_columns_mobile === "string"
                    ? parseInt(self.block_data.nb_of_columns_mobile)
                    : self.block_data.nb_of_columns_mobile;
        }
        if (self.block_data?.nb_of_columns_desktop) {
            self.columns_desktop =
                typeof self.block_data.nb_of_columns_desktop === "string"
                    ? parseInt(self.block_data.nb_of_columns_desktop)
                    : self.block_data.nb_of_columns_desktop;
        }

        // console.log('block_data', this.block_data);
    },

    mounted() {
        let self = this;
        window.addEventListener("resize", self._onResize);
        self._onResize();
        if (self.block_data.internal_link) {
            self.getInternalLink();
        }
    },

    beforeDestroy() {
        window.removeEventListener("resize", this._onResize);
    },

    methods: {
        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {
            if (
                window.innerWidth <= 1024 ||
                (window.innerWidth <= 1024 &&
                    window.matchMedia("(orientation: landscape)").matches)
            ) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },
        getInternalLink() {
            const self = this;
            axios.get("/api/getPages?language=ro").then((response) => {
                self.pages_array = response.data;
                self.internal_link_id = self.block_data.internal_link;
                self.internal_link_slug =
                    self.pages_array[self.internal_link_id];
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.content_wrapper {

    .text_wrapper {
        ::v-deep p {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #000;

            @media (min-width: 1024px) {
                font-size: 16px;
            }
        }

        ::v-deep p:not(:last-child) {
            margin-bottom: 30px;
        }

        ::v-deep p:last-child {
            margin-bottom: 0;
        }
    }
    .more_posts {
        display: flex;
        justify-content: center;
        padding-top: 20px;

        @media (min-width: 1024px) {
            padding-top: 30px;
        }

        a {
            background-color: unset;
            border: 1px solid rgba(103, 103, 103, 0.5);
            height: 34px;
            padding: 5px 15px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #676767;
                margin-right: 10px;
                font-family: var(--default-font);
            }

            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}
</style>
