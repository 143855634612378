<template>
    <div class="card_wrapper">
        <a :href="post_slug" class="trainer_card">
            <div class="trainer_card_image" :class="{ no_image: !post_content.image }">
                <!-- post_content {{ post_content }} -->
                <base-image @imgError="onImageError" :imgSrc="getImageSrc(post_content.image)"></base-image>
            </div>
            <div class="trainer_card_content" :style="{ backgroundColor: post_content.color }">
                <h3 class="trainer_card--title">{{ post_content.name }}</h3>
            </div>
        </a>
    </div>
</template>

<script>

import BaseImage from "../common/BaseImage.vue"
import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';

export default {
    name: 'trainer-card',

    props: ['storage_path', 'post_content', 'post_slug', 'cache_custom_field', 'fallbackLogo'],

    components: { ArrowRightSvg, BaseImage },

    data() {
        return {
            svg_color: '#333333',
            image_has_error: false,
        }
    },

    computed: {
        background_color_style() {
            return {
                'background-color': `${this.post_content.color};`
            };
        },
    },

    methods: {

        getImageSrc(image) {

            if (image) {
                const image_url = image;

                if (image_url) {
                    if (image_url.indexOf('http') !== -1) {
                        return image_url+ '?preventCache=' + new Date().getTime();
                    } else {
                        return this.storage_path + '/' + image_url+ '?preventCache=' + new Date().getTime();
                    }
                } else {
                    return '';
                }

            } else {
                return '';
            }
        },

        getTitle(titles) {

            if (titles) {
                let title = titles[0];
                return title;

            } else {
                return '';
            }
        },

        onImageError(val) {

            this.image_has_error = val;
        }
    },
}
</script>

<style lang="scss" scoped>
.card_wrapper {
    height: 100%;
    font-family: inherit;

    .trainer_card {
        display: flex !important;
        flex-direction: column;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        // max-width: 375px;
        margin: 0 auto;

        @media (min-width: 1024px) {}

        .trainer_card_image {
            width: 100%;
            aspect-ratio: 1/1;
            // height: 155px;
            position: relative;

            @media (min-width: 1024px) {
                // height: 250px;
                // width: 300px;
            }

            &.no_image {
                height: 0;
            }
        }

        .trainer_card_content {
            flex-grow: 1;
            padding: 10px;
        }

        .trainer_card--title {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }
    }
}
</style>