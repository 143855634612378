<template>
    <div class="news_page">

        <base-title v-if="slider_data.title" :text="slider_data.title" :title_level="'1'"></base-title>

        <div class="news_page_subtitle" v-if="slider_data.date">
            <!-- <span v-if="slider_data.geo_location.length">{{ slider_data.geo_location }}</span>
            <span v-if="slider_data.geo_location.length && slider_data.date.length">,&nbsp;</span> -->
            <span v-if="slider_data.date">{{ slider_data.date }}</span>
        </div>
        <div class="news_page_content">
            <div>
                <div class="news_page_text wysiwyg" v-if="slider_data.description" :style="column_count_style" v-html="slider_data.description">
                </div>
            </div>

            <div v-if="has_images" class="news_page_images_wrapper">
                <div class="news_page_image_wrapper" v-for="image in current_images" @click="openFullscreenImage(image)">
                    <div class="news_page_image_container">
                        <base-image :imgSrc="setImageSrc(image)" />
                    </div>
                    <!-- <img class="news_page_image" :src="storage_file_path + '/' + image" alt=""> -->
                </div>
            </div>

            <a :href="page_details.category.full_slug_category">
                <button class="news_page_button">
                    <span>
                        {{ custom_fields.see_all_news }}
                    </span>
                    <ChevronsRight />
                </button>
            </a>
            <div v-show="ui.fullscreen_visible" class="image_fullscreen" v-fullscreen_image>
                <button class="image_fullscreen_close" @click="closeFullscreenImage">
                    &times;
                </button>

                <div class="image_fullscreen_container" :class="{ single_image: images.length == 1 }">
                    <button v-if="images.length > 1 && ui.is_desktop_resolution" class="arrow_navigation arrow_nav_prev" @click="goToSlide('prev', images.length)"></button>

                    <div class="slider_content">
                        <transition-group tag="div" :name="'slide-' + direction" class="section_slider" mode="out-in">
                            <div v-for="(image, image_index) in images" :key="image + '_' + image_index" class="section_slide" v-show="image_index === current_image_fullscreen_index" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd">
                                <base-image :imgSrc="image_index >= current_image_fullscreen_index - 2 && image_index <= current_image_fullscreen_index + 2 ? setImageSrc(image) : ''" :is_fullscreen="true" class="max-size">
                                </base-image>
                            </div>
                        </transition-group>
                    </div>
                    <button v-if="images.length > 1 && ui.is_desktop_resolution" class="arrow_navigation arrow_nav_next" @click="goToSlide('next', images.length)"></button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "./common/BaseTitle.vue"
import BaseImage from "./common/BaseImage.vue"
import ChevronsRight from "./svg-components/ChevronsRight.vue"

export default {
    name: 'section-news-page',

    props: ['storage_path', 'block_data', 'custom_fields', 'page_details'],

    components: {
        BaseTitle,
        BaseImage,
        ChevronsRight
    },

    data() {
        return {
            svg_color: '#2C4E9B',

            ui: {
                fullscreen_visible: false,
                is_mobile_resolution: false,
                is_desktop_resolution: false,
            },

            columns_desktop: null,
            columns_mobile: null,

            current_image_fullscreen_index: null,
            images: [],

            direction: 'next',
            start_x: 0,
            start_y: 0,
            end_x: 0,
            end_y: 0,
        }
    },

    directives: {
        fullscreen_image: {
            inserted: (el) => {

                if (el) {

                    let image_fullscreen = document.querySelector('.image_fullscreen');
                    if (image_fullscreen) {
                        document.querySelector('#app').insertAdjacentElement('beforeend', image_fullscreen);
                    }

                }
            },
            unbind: (el) => {
                el.remove()
            }
        },
    },

    computed: {
        storage_file_path() {
            const self = this;
            return self.storage_path;
        },

        slider_data() {
            const self = this;

            return self.block_data
        },

        column_count_style() {
            const self = this;

            console.log("ajunge");
            return {
                'column-count': `${self.ui.is_mobile_resolution ? self.columns_mobile : self.columns_desktop}`
            }
        },

        has_images() {
            const self = this;

            let has_images = false;

            if (self.block_data.carousel.length) {
                for (let image of self.block_data.carousel) {
                    if (image) {
                        has_images = true;
                        break
                    }
                }
            }

            return has_images;
        },

        current_images() {
            const self = this;

            self.images = self.slider_data.carousel;
            return self.images;
        },
    },

    created() {
        let self = this;
        // console.log('block_data', this.block_data)

        window.addEventListener('resize', self._onResize);
        self._onResize();

        self.columns_mobile = typeof self.block_data.nb_of_columns_mobile === 'string' ? parseInt(self.block_data.nb_of_columns_mobile) : self.block_data.nb_of_columns_mobile;

        self.columns_desktop = typeof self.block_data.nb_of_columns_desktop === 'string' ? parseInt(self.block_data.nb_of_columns_desktop) : self.block_data.nb_of_columns_desktop;

    },

    beforeDestroy() {
        let self = this;
        window.removeEventListener('resize', self._onResize);
    },

    methods: {
        // resize
        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {

            if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },

        setImageSrc(image_url) {
            console.log(image_url);
            if (image_url) {
                if (image_url.indexOf('http') !== -1) {
                    return image_url;
                } else {
                    return this.storage_path + '/' + image_url;
                }
            } else {
                return '';
            }
        },
        // fullscreen with slider
        openFullscreenImage(image) {
            this.current_image_fullscreen_index = this.images.indexOf(image);

            document.body.style.overflow = 'hidden';
            this.ui.fullscreen_visible = true;
        },

        closeFullscreenImage() {
            this.current_image_fullscreen_index = null;

            document.body.style.overflow = 'auto';
            this.ui.fullscreen_visible = false;
        },

        goToSlide(direction, slide_lenght) {

            if (direction == 'next') {
                this.direction = 'next';
                this.current_image_fullscreen_index = (this.current_image_fullscreen_index + 1) % slide_lenght;
            }

            if (direction == 'prev') {
                this.direction = 'prev';
                this.current_image_fullscreen_index = (this.current_image_fullscreen_index - 1 + slide_lenght) % slide_lenght;
            }
        },

        nextSlide() {
            this.goToSlide('next', this.images.length);
        },

        prevSlide() {
            this.goToSlide('prev', this.images.length);
        },

        touchStart(event) {
            this.start_x = event.touches[0].clientX;
            this.start_y = event.touches[0].clientY;
        },

        touchMove(event) {
            this.end_x = event.touches[0].clientX;
            this.end_y = event.touches[0].clientY;
        },

        touchEnd(event) {
            let x_difference;
            let y_difference;

            let threshold = 50;

            if (this.end_x === 0) {
                x_difference = 0
            } else {
                x_difference = this.start_x - this.end_x;
            }

            if (this.end_y === 0) {
                y_difference = 0
            } else {
                y_difference = this.start_y - this.end_y;
            }

            if (Math.abs(x_difference) > Math.abs(y_difference)) {
                if (x_difference > threshold) {
                    this.nextSlide()
                } else if (x_difference < -threshold) {
                    this.prevSlide()
                }
            }

            this.start_x = 0;
            this.start_y = 0;
            this.end_x = 0;
            this.end_y = 0;
        },
    }
}
</script>

<style lang="scss" scoped>
.news_page {
    width: 100%;
    position: relative;
    text-align: justify;
    font-family: var(--default-font);

    .news_page_subtitle {
        display: flex;
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
    }

    .news_page_content {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 30px 0;
        gap: 30px;

        @media (min-width: 1024px) {
            // align-items: center;
            // flex-direction: row;
            // justify-content: flex-start;
            // gap: 0;
        }

        .news_page_text {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: justify;
            color: #000;
            width: 100%;
            column-gap: 20px;

            @media (min-width: 1024px) {
                column-gap: 50px;
                font-size: 16px;
            }
        }

        .news_page_images_wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;

            .news_page_image_container {
                width: 100%;
                height: 178px;

                @media (min-width: 1024px) {
                    width: auto;
                    height: 185px;
                    cursor: pointer;
                }
            }
        }

        a {
            margin: 0 auto;

            .news_page_button {
                background-color: unset;
                border: 1px solid rgba(103, 103, 103, 0.5);
                height: 34px;
                padding: 5px 15px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                cursor: pointer;

                span {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #676767;
                    margin-right: 10px;
                    font-family: var(--default-font);
                }

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.slide-next-enter-active,
.slide-next-leave-active,
.slide-prev-enter-active,
.slide-prev-leave-active {
    transition: all 0.5s;
}

.slide-next-enter {
    transform: translate(100%, 0%);
}

.slide-next-leave-to {
    transform: translate(-100%, 0%);
}

.slide-prev-enter {
    transform: translate(-100%, 0%);
}

.slide-prev-leave-to {
    transform: translate(100%, 0%);
}
</style>