<template>
  <div class="card_wrapper">

    <template v-if="post_content.public_details == 'true'">
      <a :href="post_slug" class="athlete_card">
        <div class="athlete_card_image" :class="{ no_image: !post_content.images}">
          <!-- post_content {{ post_content.public_details }} -->
          <base-image v-if="typeof post_content.images == 'object'" @imgError="onImageError" :imgSrc="getImageSrc(post_content.images[0])"></base-image>
          <base-image v-if="typeof post_content.images == 'string'" @imgError="onImageError" :imgSrc="getImageSrc(post_content.images)"></base-image>
        </div>
        <div v-if="post_content.name || post_content.category" class="athlete_card_content" :style="{ backgroundColor: post_content.color }">
          <h3 v-if="post_content.name" class="athlete_card--name">{{ post_content.name }}</h3>
          <p v-if="post_content.category" class="athlete_card--titles">{{ cache_custom_field.category + ': ' + post_content.category }}</p>
        </div>
      </a>
    </template>
    <template v-else>
      <div class="athlete_card">
        <div class="athlete_card_image" :class="{ no_image: !post_content.images}">
          <!-- post_content {{ post_content.public_details }} -->
          <base-image v-if="typeof post_content.images == 'object'" @imgError="onImageError" :imgSrc="getImageSrc(post_content.images[0])"></base-image>
          <base-image v-if="typeof post_content.images == 'string'" @imgError="onImageError" :imgSrc="getImageSrc(post_content.images)"></base-image>
        </div>
        <div v-if="post_content.name || post_content.category" class="athlete_card_content" :style="{ backgroundColor: post_content.color }">
          <h3 v-if="post_content.name" class="athlete_card--name">{{ post_content.name }}</h3>
          <p v-if="post_content.category" class="athlete_card--titles">{{ cache_custom_field.category + ': ' + post_content.category }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import BaseImage from "../common/BaseImage.vue"
import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';

export default {
  name: 'athlete-card',

  props: ['storage_path', 'post_content', 'post_slug', 'cache_custom_field', 'fallbackLogo'],

  components: { ArrowRightSvg, BaseImage },

  data() {
    return {
      svg_color: '#333333',
      image_has_error: false,
    }
  },

  computed: {
    background_color_style() {
      return {
        'background-color': `${this.post_content.color};`
      };
    },
  },

  created() {
    var age = (new Date().getFullYear() - parseInt(this.post_content.category)) ?? 0;
    const categories = [
      { maxAge: 7, category: 'U7' },
      { maxAge: 9, category: 'U9' },
      { maxAge: 11, category: 'U11' },
      { maxAge: 13, category: 'U13' },
      { maxAge: 15, category: 'U15' },
      { maxAge: 19, category: 'Cadeti' },
      { maxAge: 22, category: 'Juniori' }
    ];

    this.post_content.category = categories.find(c => age < c.maxAge)?.category || 'Default';
      
  },

  methods: {

    getImageSrc(image) {

      console.log("image",image);
      if (image) {
        const image_url = image;

        if (image_url) {
          if (image_url.indexOf('http') !== -1) {
            return image_url+ '?preventCache=' + new Date().getTime();
          } else {
            return this.storage_path + '/' + image_url+ '?preventCache=' + new Date().getTime();
          }
        } else {
          return '';
        }

      } else {
        return '';
      }
    },

    getTitle(results) {

      if (results.length > 0) {
        let result = results[0];
        let title = result.year + ' - ' + result.title;
        return title;

      } else {
        return '';
      }
    },

    onImageError(val) {

      this.image_has_error = val;
    }
  },
}
</script>

<style lang="scss" scoped>
.card_wrapper {
  // height: 100%;
  font-family: inherit;
  color: #676767;

  // @media (min-width: 1024px) {
  //   height: 100%;
  // }

  .athlete_card {
    // display: flex !important;
    // flex-direction: column;
    // border-radius: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    // max-width: 375px;
    // margin: 0 auto;

    .athlete_card_image {
      width: 100%;
      // height: 100%;
      aspect-ratio: 1/1;
      position: relative;

      ::v-deep .base-image_wrapper {
        @media (min-width: 1024px) {
          // height: 250px;
        }
      }

      &.no_image {
        height: 0;
      }
    }

    .athlete_card_content {
      // flex-grow: 1;
      padding: 10px;
      height: 100%;
    }

    .athlete_card--name {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .athlete_card--titles {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
</style>