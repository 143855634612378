<template>
    <div class="section_buttons">
        <!-- <img src="/images/stripes.png" alt="" /> -->
        <div class="overlay"></div>
        <div class="items">
            <div
                v-for="(block, block_index) in block_data.buttons"
                :key="block + '_' + block_index"
                class="buttons"
            >
                <template v-if="block.link_type == 'internal'">
                    <a
                        v-if="block.internal_link !== null"
                        :href="internal_link_slug"
                        target="_blank"
                    >
                        <span>
                            {{ block.text_button }}
                        </span>
                        <ChevronsRight />
                    </a>
                </template>

                <template v-if="block.link_type == 'external'">
                    <a
                        v-if="block.external_link"
                        :href="block.external_link"
                        target="_blank"
                    >
                        <span>
                            {{ block.text_button }}
                        </span>
                        <ChevronsRight />
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ChevronsRight from "./svg-components/ChevronsRight.vue";

export default {
    name: "section-buttons",

    props: ["block_data"],

    components: { ChevronsRight },

    data() {
        return {
            ui: {
                is_mobile_resolution: false,
                is_desktop_resolution: false,
            },

            columns_desktop: null,
            columns_mobile: null,
            internal_link_slug: null,
        };
    },

    computed: {
        column_count_style() {
            return {
                "column-count": `${
                    this.ui.is_mobile_resolution
                        ? this.columns_mobile
                        : this.columns_desktop
                }`,
            };
        },
    },

    watch: {},

    created() {
        let self = this;

        self.columns_mobile =
            typeof self.block_data.nb_of_columns_mobile === "string"
                ? parseInt(self.block_data.nb_of_columns_mobile)
                : self.block_data.nb_of_columns_mobile;

        self.columns_desktop =
            typeof self.block_data.nb_of_columns_desktop === "string"
                ? parseInt(self.block_data.nb_of_columns_desktop)
                : self.block_data.nb_of_columns_desktop;

        // console.log('block_data', this.block_data);
    },

    mounted() {
        let self = this;

        window.addEventListener("resize", self._onResize);
        self._onResize();
        self.getInternalLink();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this._onResize);
    },

    methods: {
        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {
            if (
                window.innerWidth <= 1024 ||
                (window.innerWidth <= 1024 &&
                    window.matchMedia("(orientation: landscape)").matches)
            ) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },
        getInternalLink() {
            const self = this;
            axios.get("/api/getPages?language=ro").then((response) => {
                self.pages_array = response.data;
                self.internal_link_id = self.block_data.internal_link;
                self.internal_link_slug =
                    self.pages_array[self.internal_link_id];
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay{
    background-image: url('/images/stripes.png');
    opacity: 5%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    position: absolute;
}
.section_buttons {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 !important;
    background: linear-gradient(90deg, #69AFAF 0%, #AECFEE 97.07%);
    width: 100%;
    height: 54px;
    margin-top: 0;

    @media (min-width: 1024px) {
        padding-top: 30px;
    }
}

.items {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 100%;
    justify-content: space-around;
}


.buttons {
    display: flex;
    align-items: center;

    a {
        background-color: #fff;
        border: 1px solid rgba(103, 103, 103, 0.5);
        height: 34px;
        padding: 5px 15px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #676767;
            margin-right: 10px;
            font-family: var(--default-font);
        }

        img {
            width: 24px;
            height: 24px;
        }
    }
}
</style>
