<template>
  <div>
    <template v-if="title_level">
      <h1 v-if="title_level == '1'" :class="['title', textAlignCenter ? 'text_align_center' : 'text_align_center_mobile']" :style="textStyle">{{ text }}</h1>
      <h2 v-if="title_level == '2'" :class="['title', textAlignCenter ? 'text_align_center' : 'text_align_center_mobile']" :style="textStyle">{{ text }}</h2>
      <h3 v-if="title_level == '3'" :class="['title', textAlignCenter ? 'text_align_center' : 'text_align_center_mobile']" :style="textStyle">{{ text }}</h3>
      <h4 v-if="title_level == '4'" :class="['title', textAlignCenter ? 'text_align_center' : 'text_align_center_mobile']" :style="textStyle">{{ text }}</h4>
      <h5 v-if="title_level == '5'" :class="['title', textAlignCenter ? 'text_align_center' : 'text_align_center_mobile']" :style="textStyle">{{ text }}</h5>
      <h6 v-if="title_level == '6'" :class="['title', textAlignCenter ? 'text_align_center' : 'text_align_center_mobile']" :style="textStyle">{{ text }}</h6>
    </template>
    <template v-else>
      <h2 class="title" :style="textStyle">{{ text }}</h2>
    </template>
  </div>
</template>

<script>
export default {
  name: 'base-title',
  props: {
    text: {
      type: String
    },
    textAlignCenter: {
      type: Boolean,
      default: false
    },
    textSize: {
    },
    title_level: {
      type: String
    }
  },

  computed: {
    textStyle() {
      if (this.textSize) {

        const text_size = typeof this.textSize === 'string' ? parseInt(this.textSize) : this.textSize;

        return {
          fontSize: text_size + 'px',
          lineHeight: text_size + 5 + 'px',
        };

      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  // width: fit-content;
  // margin-bottom: 40px;
  // font-size: 20px;
  // line-height: normal;
  // font-weight: 600;
  // font-family: var(--default-font);
  // color: var(--text_color);

  font-family: var(--default-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background: linear-gradient(90deg, #69AFAF 0%, #DAC2FE 53.12%, #AECFEE 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  padding-left: 10px;
  left: -10px;
  position: relative;
  margin-bottom: 30px;
  border-left: 8px solid #69AFAF;

  @media (min-width: 1024px) {
    left: 0;
    font-size: 18px;
  }
}
</style>