<template>
    <div class="content_wrapper" :class="{ single_image: slider_data.length == 1 }">
        <div class="images_slider_container">

            <button v-if="slider_data.length > 1 && ui.is_desktop_resolution" class="arrow_navigation arrow_nav_prev" @click="goToSlide(current_index - 1)" :disabled="current_index === 0"></button>

            <div class="slider_content">
                <transition-group tag="div" :name="'slide-' + direction" class="section_slider" mode="out-in">
                    <div v-for="(slide, slide_index) in slider_data" :key="slide + '_' + slide_index" class="section_slide" v-show="slide_index === current_index" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd" @click="openFullscreenImage(slide)">
                        <base-image :imgSrc="getImageSrc(slide.image || slide)"></base-image>
                    </div>
                </transition-group>
            </div>

            <button v-if="slider_data.length > 1 && ui.is_desktop_resolution" class="arrow_navigation arrow_nav_next" @click="goToSlide(current_index + 1)" :disabled="current_index === slider_data.length - 1"></button>


            <div v-if="slider_data.length > 1 && ui.is_mobile_resolution" class="dots_navigation">
                <div class="outer_dot" v-for="(slide, index) in slider_data" :key="index" @click="goToSlide(index)" :class="{ active_slide: current_index === index }">
                    <div class="inner_dot"></div>
                </div>
            </div>

        </div>

        <div v-show="ui.fullscreen_visible" class="image_fullscreen" v-fullscreen_image>
            <button class="image_fullscreen_close" @click="closeFullscreenImage">
                &times;
            </button>

            <div class="image_fullscreen_container" :class="{ single_image: slider_data.length == 1 }">

                <button v-if="slider_data.length > 1" class="arrow_navigation arrow_nav_prev" @click="goToSlide(current_index - 1)" :disabled="current_index === 0"></button>

                <div class="slider_content">
                    <transition-group tag="div" :name="'slide-' + direction" class="section_slider" mode="out-in">
                        <div v-for="(slide, slide_index) in slider_data" :key="slide + '_' + slide_index" class="section_slide" v-show="slide_index === current_index" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd">
                            <base-image :imgSrc="getImageSrc(slide.image || slide)" :is_fullscreen="true" class="max-size"></base-image>
                        </div>
                    </transition-group>
                </div>

                <button v-if="slider_data.length > 1" class="arrow_navigation arrow_nav_next" @click="goToSlide(current_index + 1)" :disabled="current_index === slider_data.length - 1"></button>


            </div>
        </div>
    </div>
</template>

<script>

import BaseImage from "./common/BaseImage.vue"

export default {
    name: 'section-slider-images',

    props: ['storage_path', 'slider'],

    components: {
        BaseImage,
    },

    data() {
        return {
            ui: {
                is_mobile_resolution: false,
                is_desktop_resolution: false,
                fullscreen_visible: false,
            },

            current_index: 0,
            direction: 'next',
            is_animating: false,
            auto_slide: {
                interval: null,
                seconds: 5000,
                active: false
            },
            start_x: 0,
            start_y: 0,
            end_x: 0,
            end_y: 0,
        }
    },

    directives: {
        fullscreen_image: {
            inserted: (el, binding, vnode) => {

                if (el) {

                    let image_fullscreen = document.querySelector('.image_fullscreen');
                    if (image_fullscreen) {
                        document.querySelector('#app').insertAdjacentElement('beforeend', image_fullscreen);
                    }

                }
            },
            unbind: (el) => {
                el.remove()
            }
        },
    },

    computed: {
        slider_data() {
            if (this.slider.images) {
                return this.slider.images;
            } else {
                return this.slider
            }
        },
    },

    created() {
        // console.log('storage_path', this.storage_path, 'slider', this.slider);
    },

    mounted() {
        if (this.auto_slide.active) {
            this.startAutoSlide()
        }

        window.addEventListener('resize', this._onResize);
        this._onResize();
    },

    beforeDestroy() {
        this.stopAutoSlide();
        window.removeEventListener('resize', this._onResize);
    },

    methods: {
        goToSlide(index) {
            const self = this;
            if (self.current_index < index) {
                self.direction = 'next';
            }
            if (self.current_index > index) {
                self.direction = 'prev';
            }

            self.current_index = index;

            this.resetAutoSlide();
        },

        nextSlide() {
            const self = this;
            if (self.is_animating) return;
            self.is_animating = true;
            self.direction = 'next';
            self.current_index = (self.current_index + 1) % self.slider_data.length;

            this.resetAutoSlide();

            setTimeout(() => {
                this.is_animating = false;
            }, 550);
        },

        prevSlide() {
            const self = this;
            if (self.is_animating) return;
            self.is_animating = true;
            self.direction = 'prev';
            self.current_index = (self.current_index - 1 + self.slider_data.length) % self.slider_data.length;

            this.resetAutoSlide();

            setTimeout(() => {
                this.is_animating = false;
            }, 550);
        },

        startAutoSlide() {
            if (this.auto_slide.interval) {
                clearInterval(this.auto_slide.interval);
            }

            this.auto_slide.interval = setInterval(() => {
                this.nextSlide()
            }, this.auto_slide.seconds);
        },

        stopAutoSlide() {
            if (this.auto_slide.interval) {
                clearInterval(this.auto_slide.interval);
            }
        },

        resetAutoSlide() {
            if (this.auto_slide.active) {
                this.stopAutoSlide();
                this.startAutoSlide()
            }
        },

        touchStart(event) {
            this.start_x = event.touches[0].clientX;
            this.start_y = event.touches[0].clientY;
        },

        touchMove(event) {
            this.end_x = event.touches[0].clientX;
            this.end_y = event.touches[0].clientY;
        },

        touchEnd(event) {
            let x_difference;
            let y_difference;

            let threshold = 50;

            if (this.end_x === 0) {
                x_difference = 0
            } else {
                x_difference = this.start_x - this.end_x;
            }

            if (this.end_y === 0) {
                y_difference = 0
            } else {
                y_difference = this.start_y - this.end_y;
            }

            if (Math.abs(x_difference) > Math.abs(y_difference)) {
                if (x_difference > threshold) {
                    this.nextSlide()
                } else if (x_difference < -threshold) {
                    this.prevSlide()
                }
            }

            this.start_x = 0;
            this.start_y = 0;
            this.end_x = 0;
            this.end_y = 0;
        },

        getImageSrc(image_url) {

            if (image_url) {
                return this.storage_path + '/' + image_url+ '?preventCache=' + new Date().getTime();
            } else {
                return '';
            }
        },

        openFullscreenImage(image) {
            // console.log(image, this.fullscreen_image);
            if (this.ui.is_mobile_resolution) {
                return
            }
            this.fullscreen_image = image;
            this.ui.fullscreen_visible = true;
            document.body.style.overflow = 'hidden';
        },

        closeFullscreenImage() {
            document.body.style.overflow = 'auto';
            this.ui.fullscreen_visible = false;
            this.fullscreen_image = '';
        },

        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {

            if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.content_wrapper {
    $slider-nav-btn-width: 48px;
    $slider-gap: 15px;
    $slider-content-max-width: 474px;

    width: 100%;

    max-width: calc($slider-content-max-width);

    @media (min-width: 1024px) {
        max-width: calc($slider-content-max-width + 2 * $slider-gap + 2 * $slider-nav-btn-width);
    }

    .images_slider_container {
        position: relative;
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
            flex-direction: row;
            align-items: center;
            gap: $slider-gap;
        }

        .arrow_navigation {
            width: $slider-nav-btn-width;
            height: $slider-nav-btn-width;
            padding: 0;
            border: none;
            background: transparent;
            color: transparent;
            opacity: 0.8;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }

            &:disabled {
                opacity: 0.25;
            }

            &.arrow_nav_prev {
                background: url(/images/angle_left_slider.svg) center/100% no-repeat;
            }

            &.arrow_nav_next {
                background: url(/images/angle_right_slider.svg) center/100% no-repeat;
            }
        }

        .dots_navigation {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;

            @media (min-width: 1024px) {
                padding-top: 30px;
            }

            .outer_dot {
                position: relative;
                width: 18px;
                height: 18px;
                border: 1px solid #69AFAF;
                background-color: #ffffff;
                border-radius: 50%;

                cursor: pointer;

                &:not(:last-of-type) {
                    margin-right: 3px;

                    @media (min-width: 768px) {
                        margin-right: 10px;
                    }
                }

                &.active_slide {
                    .inner_dot {
                        background-color: #69AFAF;
                        opacity: 1;
                        transition: all 0.3s ease-in-out;
                    }
                }

                .inner_dot {
                    position: absolute;
                    transition: all 0.3s ease-in-out;
                    border: 1px solid transparent;
                    background-color: #ffffff;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                }
            }
        }

        .slider_content {
            max-width: $slider-content-max-width;
            flex-grow: 1;
            border-radius: 10px;
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
            overflow: hidden;

            .section_slider {
                position: relative;
                height: 100%;
                width: 100%;
                min-height: 316px;

                .section_slide {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: block;
                    height: 100%;
                    width: 100%;

                    cursor: pointer;

                    // .section_slide_image {
                    //     width: 100%;
                    //     height: 100%;
                    //     overflow: hidden;

                    //     img {
                    //         display: block;
                    //         width: 100%;
                    //         height: 316px;
                    //         object-fit: cover;
                    //     }
                    // }
                }
            }
        }
    }

    &.single_image {
        @media (min-width: 1024px) {
            max-width: calc($slider-content-max-width);
        }

        .images_slider_container {

            .slider_content {
                .section_slider {
                    min-height: unset;

                    .section_slide {
                        position: static;
                    }
                }
            }
        }
    }
}

.slide-next-enter-active,
.slide-next-leave-active,
.slide-prev-enter-active,
.slide-prev-leave-active {
    transition: all 0.5s;
}

.slide-next-enter {
    transform: translate(100%, 0%);
}

.slide-next-leave-to {
    transform: translate(-100%, 0%);
}

.slide-prev-enter {
    transform: translate(-100%, 0%);
}

.slide-prev-leave-to {
    transform: translate(100%, 0%);
}
</style>