<template>
    <div class="content_wrapper">
        <base-title v-if="block_data.title" :text="block_data.title" :text-align-center="ui.is_mobile_resolution"></base-title>

        <div v-if="ui.is_loading_posts">
            <loading-indicator type="spinner"></loading-indicator>
        </div>
        <div v-if="ui.no_posts" class="error_wrapper">
            <p>Error</p>
        </div>

        <div v-if="!ui.is_loading_posts && !ui.no_posts" class="slider_wrapper" :id="carousel_id">
            <template v-if="posts.template === 'stiri'">
                <news-card v-for="(post, index) in posts.values" :key="index" :post_content="post.content" :post_slug="post.slug" :storage_path="storage_path" :cache_custom_field="custom_field" :fallback-logo="true">
                </news-card>
            </template>
            <template v-else-if="posts.template === 'antrenor'">
                <trainer-card v-for="(post, index) in posts.values" :key="index" :post_content="post.content" :post_slug="post.slug" :storage_path="storage_path" :cache_custom_field="custom_field" :fallback-logo="true">
                </trainer-card>
            </template>
            <template v-else-if="posts.template === 'sportiv'">
                <athlete-card v-for="(post, index) in shuffledAthletes" :key="index" :post_content="post.content" :post_slug="post.slug" :storage_path="storage_path" :cache_custom_field="custom_field" :fallback-logo="true">
                </athlete-card>
            </template>
        </div>

        <div v-if="!ui.is_loading_posts && !ui.no_posts && block_data.text_button" class="more_posts">
            <a :href="posts.slug">
                <span>{{ block_data.text_button }}</span>
                <ChevronsRight />
            </a>
        </div>
    </div>
</template>

<script>
import axios from "axios";

import BaseTitle from './common/BaseTitle.vue';
import LoadingIndicator from './common/LoadingIndicator.vue';

import NewsCard from './cards/NewsCard.vue';
import TrainerCard from './cards/TrainerCard.vue';

import ArrowRightSvg from './svg-components/ArrowRightSvg.vue';
import ChevronsRight from './svg-components/ChevronsRight.vue';
import AthleteCard from "./cards/AthleteCard.vue";

export default {
    name: 'section-post-slider',

    props: ['image_path', 'storage_path', 'block_data', 'custom_field'],

    components: {
        BaseTitle,
        LoadingIndicator,

        NewsCard,
        AthleteCard,
        TrainerCard,
        ArrowRightSvg,
        ChevronsRight,
    },

    data() {
        return {
            ui: {
                is_loading_posts: false,
                no_posts: false,
                is_mobile_resolution: false,
                is_desktop_resolution: false,
                nb_of_posts_desktop: 3,
                nb_of_posts_mobile: 4,
                svg_color: '#333333',
            },

            carousel_id: '',

            posts_base_api: '/api/getPostByCategoryId/',
            posts: {},
        }
    },

    computed: {
        shuffledAthletes() {
            let self = this;

            let slicedArray = [];

            if (self.posts.template == 'sportiv') {
                self.posts.values.sort(() => Math.random() - 0.5);

                if (self.ui.is_desktop_resolution) {
                    slicedArray = self.posts.values.slice(0, self.ui.nb_of_posts_desktop);
                } else {
                    slicedArray = self.posts.values.slice(0, self.ui.nb_of_posts_mobile);
                }

                return slicedArray;
            }
        }
    },

    created() {
    },

    mounted() {
        let self = this;

        self.getPosts();

        window.addEventListener('resize', self._onResize);
        self._onResize();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this._onResize);
    },

    methods: {
        getPosts() {
            var self = this;

            const post_category = self.block_data.post_categories;
            const posts_base_api = self.posts_base_api;

            const posts_api = posts_base_api + post_category;

            self.ui.is_loading_posts = true;

            axios.get(posts_api)
                .then((response) => {
                    if (response.data.values.length) {

                        let data = response.data;

                        for (let key in data) {


                            Vue.set(self.posts, key, data[key])
                        }

                        if (self.posts.template != 'sportiv') {
                            self.getFirstPosts();
                        }

                        self.setCarouselId();

                    } else {

                        self.ui.no_posts = true;

                    }

                    self.ui.is_loading_posts = false;

                }).catch((error) => {

                    self.ui.no_posts = true;
                    self.ui.is_loading_posts = false;
                })
        },

        setCarouselId() {

            this.carousel_id = 'posts-' + this.block_data.post_categories + '-' + Date.now();
        },

        getFirstPosts() {
            let self = this;

            let slicedArray = [];

            if (self.ui.is_desktop_resolution) {
                slicedArray = self.posts.values.slice(0, self.ui.nb_of_posts_desktop);
            } else {
                slicedArray = self.posts.values.slice(0, self.ui.nb_of_posts_mobile);
            }

            self.posts.values = slicedArray;
        },

        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {

            if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.slider_wrapper {
    position: relative;
    flex-wrap: wrap;
    display: flex;
    margin: -10px;

    @media (min-width: 1024px) {
        margin: 0px;
        gap: 10px;
        flex-wrap: nowrap;
        padding: 0 10px;
    }

    .card_wrapper {
        width: 50%;
        padding: 10px;

        @media (min-width: 1024px) {
            // width: 100%;
            padding: 0;
            width: 320px;
        }

        ::v-deep &>a.athlete_card>div.athlete_card_image {
            // width: 100%;
            // height: 115px;

            // @media (min-width: 1024px) {
            //     height: 250px;
            // }

            .base-image_wrapper {
                width: 100%;
                // height: 115px;

                @media (min-width: 1024px) {
                    // height: 250px;
                }
            }
        }

        ::v-deep &>a.athlete_card>div.athlete_card_content {
            padding: 10px;
        }

    }
}

.more_posts {
    display: flex;
    justify-content: center;
    padding-top: 14px;

    @media (min-width: 1024px) {
        padding-top: 30px;
    }

    a {
        background-color: unset;
        border: 1px solid rgba(103, 103, 103, 0.5);
        height: 34px;
        padding: 5px 15px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #676767;
            margin-right: 10px;
            font-family: var(--default-font);
        }

        img {
            width: 24px;
            height: 24px;
        }
    }
}
</style>