

import Vue from "vue";

import $ from 'jquery';
import 'slick-carousel';

window.jQuery = window.$ = $;

window.Vue = Vue;
// Vue components
import HPSlider from './components/HPSlider.vue'
Vue.component('hp-slider', HPSlider);

import BaseTitle from './components/common/BaseTitle.vue';
Vue.component('base-title', BaseTitle);

import BaseTable from './components/common/BaseTable.vue';
Vue.component('base-table', BaseTable);

import SectionNewsPage from './components/SectionNewsPage.vue';
Vue.component('section-news-page', SectionNewsPage);

import SectionText from './components/SectionText.vue';
Vue.component('section-text', SectionText);

import SectionButtons from './components/SectionButtons.vue';
Vue.component('section-buttons', SectionButtons);

import SectionTitleWithDescription from './components/SectionTitleWithDescription.vue';
Vue.component('section-title-with-description', SectionTitleWithDescription);

import ImagesGrid from './components/ImagesGrid.vue';
Vue.component('images-grid', ImagesGrid);

import PostSlider from './components/PostSlider.vue'
Vue.component('section-post-slider', PostSlider);

import SectionTrainer from './components/SectionTrainer.vue';
Vue.component('section-trainer', SectionTrainer);

import SectionAthlete from './components/SectionAthlete.vue';
Vue.component('section-athlete', SectionAthlete);

import PostsGrid from './components/PostsGrid.vue';
Vue.component('posts-grid', PostsGrid);

import FreeLessonPage from './components/FreeLessonPage.vue';
Vue.component('free-lesson-page', FreeLessonPage);

import SectionSliderImages from './components/SectionSliderImages.vue';
Vue.component('section-slider-images', SectionSliderImages);

const app = new Vue({
    el: '#app',
})


window.app = app;


// ! Cookies consent
const cookiesPopup = document.querySelector('#cookie');
const allowBtnCookies = document.querySelector('.cookie_consent_btn');
const cookiesName = 'playride';

if (cookiesPopup && allowBtnCookies && cookiesName) {
    cookiesConsent(cookiesPopup, allowBtnCookies, cookiesName);
}

// function scrollTop() {
//     const scrollTop = document.querySelector(".scrolltop");
//     if (this.scrollY >= 560) scrollTop.classList.add("show-scroll");
//     else scrollTop.classList.remove("show-scroll");
// }
// window.addEventListener("scroll", scrollTop);

const buttonBurger = document.querySelector(".q10__header__top__burger_menu");
const burgerImage = document.querySelector(".q10__header__top__burger");
const burgerMenu = document.querySelector(".q10__header__popup");
const targetMenu = document.querySelector(".dropdown-content")
const selectMenu = document.querySelector("#selected")
const angleDown = document.querySelector(".angle_down");

buttonBurger.addEventListener("click", (e) => {
    if (!burgerMenu.classList.contains("active")) {
        burgerMenu.classList.add("active");
        burgerImage.classList.add("active")
        document.body.style.overflow = "hidden";
    } else {
        burgerMenu.classList.remove("active");
        document.body.style.overflow = "auto"
        burgerImage.classList.remove("active");
    }

    // if (burgerImage.classList.contains('active')) {
    //     burgerImage.src = "/images/close.svg";
    // } else {
    //     burgerImage.src = "/images/burger_menu.svg";
    // }
});

window.openLang = function () {
    if (targetMenu.classList.contains('show')) {
        targetMenu.classList.remove('show');
        selectMenu.style = "box-shadow: 0;"
        angleDown.style.transform = "rotate(0deg)";
        angleDown.style.left = "0px";
    } else {
        targetMenu.classList.add('show');
        angleDown.style.transform = "rotate(180deg)";
        angleDown.style.left = "-5px";
    }
}

window.addEventListener("openLang", openLang);

// document.addEventListener("click", (e) => {
//     const isClosest = e.target.closest('#selected');

//     if (!isClosest && targetMenu.classList.contains("show")) {
//         targetMenu.classList.remove("show");
//     }
// });

if (window.matchMedia("(min-width: 1024px)").matches) {

    let childrenArray = Array.prototype.slice.apply(document.querySelectorAll('.item_children'));

    childrenArray.some(function (el) {
        let parentChildren = Array.prototype.slice.call(el.children)
        parentChildren.forEach(element => {
            const parentElement = element.closest(".item_parent");
            if (element.classList.contains('item_selected')) {
                parentElement.classList.add('item_selected')
            }
        });
    });

}