<template>
    <div class="athlete_card">
        <base-title v-if="slider_data.name" :text="slider_data.name"></base-title>

        <div class="athlete_card_content">
            <!-- <div class="athlete_card_image" v-if="slider_data.image" :class="{ no_text: !slider_data.text }">
                <base-image :imgSrc="getImageSrc(slider_data)"></base-image>
            </div> -->
            <!-- <div v-if="has_images" class="athlete_card_images_wrapper">
                <div class="athlete_card_image_wrapper" v-for="image in current_images" @click="openFullscreenImage(image)">
                    <div class="athlete_card_image_container">
                        <base-image :imgSrc="setImageSrc(image)" />
                    </div>
                </div>
            </div> -->

            <div class="athlete_card_images_wrapper">
                <section-slider-images 
                    :storage_path="storage_path" 
                    :slider="slider_data">
                </section-slider-images>
            </div>

            <div class="athlete_card_details" v-if="slider_data.text" :class="{ no_image: !slider_data.images }">
                <div class="athlete_card_description">
                    <div class="athlete_card_text wysiwyg" v-if="slider_data.text" v-html="slider_data.text">
                    </div>
                </div>
            </div>
        </div>
        <div class="athlete_card_tables" v-if="slider_data.titles || slider_data.results">
            <base-table v-if="slider_data.titles" :table_title="slider_data.titles_label" :array_elements="slider_data.titles"></base-table>
            <base-table v-if="slider_data.results" :table_title="slider_data.results_label" :array_elements="slider_data.results"></base-table>
        </div>
    </div>
</template>

<script>
import BaseTitle from "./common/BaseTitle.vue"
import BaseImage from "./common/BaseImage.vue"
import BaseTable from "./common/BaseTable.vue"
import SectionSliderImages from "./SectionSliderImages.vue"

export default {
    name: 'section-athlete',

    props: ['storage_path', 'block_data', 'custom_fields', 'page_details'],

    components: {
        BaseTitle,
        BaseImage,
        BaseTable,
        SectionSliderImages
    },

    data() {
        return {
            svg_color: '#2C4E9B',
            current_image_fullscreen_index: null,
            images: [],

        }
    },

    computed: {
        storage_file_path() {
            const self = this;
            return self.storage_path;
        },

        slider_data() {
            const self = this;

            return self.block_data;
        },

        has_images() {
            const self = this;

            let has_images = false;

            console.log("self.block_data", self.block_data);

            if (self.block_data.images.length) {
                for (let image of self.block_data.images) {
                    if (image) {
                        has_images = true;
                        break
                    }
                }
            }

            return has_images;
        },

        current_images() {
            const self = this;

            self.images = self.slider_data.images;

            console.log("self.images", self.images);
            return self.images;
        },
    },

    created() {
        // console.log('block_data', this.block_data)
    },

    methods: {
        getImageSrc(slide) {

            let image_path = "";

            if (slide.image) {
                image_path = slide.image+ '?preventCache=' + new Date().getTime();
            } else if (slide.content && slide.content.image) {
                image_path = slide.content.image+ '?preventCache=' + new Date().getTime();
            } else if (slide.content && slide.content.images && slide.content.images[0] && slide.content.images[0].image) {
                image_path = slide.content.images[0].image+ '?preventCache=' + new Date().getTime();
            } else if (slide.file_name) {
                image_path = slide.file_name+ '?preventCache=' + new Date().getTime();
            }

            if (!image_path.includes('http')) {
                image_path = this.storage_file_path + '/' + image_path+ '?preventCache=' + new Date().getTime();
            }

            return image_path+ '?preventCache=' + new Date().getTime();
        },

        // fullscreen with slider
        openFullscreenImage(image) {
            this.current_image_fullscreen_index = this.images.indexOf(image);

            document.body.style.overflow = 'hidden';
            this.ui.fullscreen_visible = true;
        },

        setImageSrc(image_url) {
            console.log(image_url);
            if (image_url) {
                if (image_url.indexOf('http') !== -1) {
                    return image_url;
                } else {
                    return this.storage_path + '/' + image_url;
                }
            } else {
                return '';
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.athlete_card {
    width: 100%;
    position: relative;

    .athlete_card_subtitle {
        display: flex;
    }

    .athlete_card_content {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 30px;

        @media (min-width: 1024px) {
            // align-items: center;
            flex-direction: row;
            justify-content: flex-start;
            gap: 0px;
        }

        .athlete_card_details {
            order: 2;

            @media (min-width: 1024px) {
                width: 50%;
                order: 1;
            }

            &.no_image {
                @media (min-width: 1024px) {
                    width: 100%;
                }
            }

            .athlete_card_description {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #000;

                @media (min-width: 1024px) {
                    font-size: 16px;
                }
            }


        }

        .athlete_card_images_wrapper {
            order: 1;
            width: 100%;

            @media (min-width: 1024px) {
                width: 50%;
                order: 2;
                display: flex;
                justify-content: center;
            }

            .content_wrapper.single_image {
                width: 100%;
                aspect-ratio: 1/1;

                @media (min-width: 1024px) {
                    max-width: 355px;
                }
            }
        }

        .athlete_card_image {
            display: grid;
            place-content: center;
            order: 1;

            @media (min-width: 1024px) {
                width: 50%;
                order: 2;
            }

            &.no_text {
                @media (min-width: 1024px) {
                    width: 100%;
                }
            }

            .base-image_wrapper {

                width: 100%;
                aspect-ratio: 1/1;

                @media (min-width: 1024px) {
                    max-width: 355px;
                }
            }
        }
    }

    .athlete_card_tables {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        ::v-deep .base_table {
            margin-top: 20px;
        }
    }
}
</style>