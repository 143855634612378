<template>
  <div class="card_wrapper">
    <a :href="post_slug" class="news_card">
      <div class="news_card_image" :class="{ no_image: post_content.carousel.length == 0 }">

        <div v-if="post_content.carousel.length > 0" class="news_card_image_overlay"></div>
        <base-image v-if="post_content.carousel.length" @imgError="onImageError" :imgSrc="getImageSrc(post_content.carousel)"></base-image>
        <div v-else class="no_image_background">
          <div v-if="post_content.carousel.length == 0" class="news_card_content" :class="{ 'no-image': post_content.carousel.length == 0 }">
            <p class="news_card--date" v-html="getDate(post_content.date)"></p>
            <h3 class="news_card--title">{{ post_content.title }}</h3>
          </div>
        </div>
        <div v-if="post_content.carousel.length > 0" class="news_card_content">
          <p class="news_card--date" v-html="getDate(post_content.date)"></p>
          <h3 class="news_card--title">{{ post_content.title }}</h3>
        </div>
      </div>
    </a>
  </div>
</template>

<script>

import BaseImage from "../common/BaseImage.vue"
import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';

export default {
  name: 'news-card',

  props: ['storage_path', 'post_content', 'post_slug', 'cache_custom_field', 'fallbackLogo'],

  components: { ArrowRightSvg, BaseImage },

  data() {
    return {
      svg_color: '#333333',
      image_has_error: false,
    }
  },

  methods: {

    getImageSrc(images) {

      if (images.length) {
        const image_url = images[0];

        if (image_url) {
          if (image_url.indexOf('http') !== -1) {
            return image_url+ '?preventCache=' + new Date().getTime();
          } else {
            return this.storage_path + '/' + image_url+ '?preventCache=' + new Date().getTime();
          }
        } else {
          return '';
        }

      } else {
        return '';
      }
    },

    getDate(date_details) {

      if (date_details) {
        let date = date_details.split('/')[0];
        return date;

      } else {
        return '';
      }
    },

    onImageError(val) {

      this.image_has_error = val;
    }
  },
}
</script>

<style lang="scss" scoped>
.card_wrapper {
  height: 100%;
  font-family: inherit;

  .news_card {
    display: flex !important;
    flex-direction: column;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    // max-width: 375px;
    gap: 20px;

    margin: 0 auto;

    @media (min-width: 1024px) {}

    .news_card_image_overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .news_card_image {
      width: 100%;
      height: 230px;
      position: relative;

      .no_image_background {
        width: 100%;
        background: linear-gradient(180deg, rgba(218, 194, 254, 0.6) 0%, rgba(174, 207, 238, 0.6) 100%);
      }
    }

    .no_image {
      height: 100%;
    }

    .news_card_content {
      flex-grow: 1;
      bottom: 0;
      position: absolute;
      padding: 10px;
      color: #fff;
      z-index: 1;

      &.no-image {
        color: #676767;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
      }

    }


    .news_card--date {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .news_card--title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .news_card--preview {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    .news_card--read-more {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;

      border: 1px solid #333;
      border-radius: 10px;
      background-color: #fff;

      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: #333333;
    }
  }
}
</style>